.info-container {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.info-hero {
  width: 100%;
  height: 625px;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.info-image {
  width: 40%;
  object-fit: cover;
}
.info-container1 {
  flex: 0 0 auto;
  width: 60%;
  height: auto;
  display: flex;
  align-items: space-between;
  margin-left: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.info-heading {
  color: var(--dl-color-gray-black);
  width: auto;
  height: auto;
  margin-bottom: var(--dl-space-space-unit);
}
.info-age-container {
  flex: 0 0 auto;
  width: 75%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.info-since-container {
  flex: 0 0 auto;
  width: 75%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.info-text11 {
  top: 0px;
  right: 0px;
  position: absolute;
}
.info-weight-container {
  flex: 0 0 auto;
  width: 75%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.info-text17 {
  top: 0px;
  right: 0px;
  position: absolute;
}
.info-ftp-container {
  flex: 0 0 auto;
  width: 75%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.info-text23 {
  top: 0px;
  right: 0px;
  position: absolute;
}
.info-wkg-container {
  flex: 0 0 auto;
  width: 75%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.info-text27 {
  top: 0px;
  right: 0px;
  position: absolute;
}
.info-social-bar {
  width: 75%;
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-tripleunit);
  justify-content: center;
}
.info-link {
  display: contents;
}
.info-icon {
  fill: var(--dl-color-primary-100);
  width: 25px;
  height: 25px;
  margin-right: 32px;
  text-decoration: none;
}
.info-link1 {
  display: contents;
}
.info-icon2 {
  fill: var(--dl-color-primary-100);
  width: 25px;
  height: 25px;
  margin-right: 32px;
  text-decoration: none;
}
.info-link2 {
  display: contents;
}
.info-icon4 {
  fill: var(--dl-color-primary-100);
  width: 25px;
  height: 25px;
  margin-right: 32px;
  text-decoration: none;
}
.info-link3 {
  display: contents;
}
.info-image1 {
  width: 25px;
  height: 25px;
  object-fit: cover;
  text-decoration: none;
}
.info-root-class-name {
  height: auto;
}
@media(max-width: 991px) {
  .info-hero {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .info-container1 {
    margin-left: 0px;
  }
  .info-heading {
    color: var(--dl-color-primary-100);
    width: 100%;
    align-self: center;
    text-align: center;
  }
  .info-text {
    text-align: center;
  }
  .info-age-container {
    width: 100%;
  }
  .info-since-container {
    width: 100%;
  }
  .info-weight-container {
    width: 100%;
  }
  .info-ftp-container {
    width: 100%;
  }
  .info-wkg-container {
    width: 100%;
  }
  .info-social-bar {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .info-container1 {
    margin-left: 0px;
  }
  .info-social-bar {
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .info-hero {
    background-color: var(--dl-color-primary-300);
  }
  .info-image {
    width: 60%;
  }
  .info-container1 {
    width: 90%;
    margin-left: 0px;
  }
  .info-heading {
    text-align: center;
  }
  .info-age-container {
    width: 100%;
  }
  .info-since-container {
    width: 100%;
  }
  .info-text11 {
    bottom: 0px;
  }
  .info-weight-container {
    width: 100%;
  }
  .info-ftp-container {
    width: 100%;
  }
  .info-wkg-container {
    width: 100%;
  }
  .info-social-bar {
    width: 100%;
    margin-top: 0px;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .info-image1 {
    width: 25px;
    margin-right: 0px;
  }
  .info-root-class-name {
    height: auto;
    position: 0px;
  }
}
