.races-container {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.races-ostatnie-starty {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 100%;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.races-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.races-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.races-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
}
.races-heading {
  color: var(--dl-color-gray-black);
  width: auto;
  height: auto;
  margin-bottom: var(--dl-space-space-unit);
}
.races-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.races-weight-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.races-text05 {
  top: 0px;
  right: 0px;
  position: absolute;
}
.races-age-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.races-text09 {
  top: 0px;
  right: 0px;
  position: absolute;
}
.races-since-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.races-text15 {
  top: 0px;
  right: 0px;
  position: absolute;
}
.races-since-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.races-text21 {
  top: 0px;
  right: 0px;
  position: absolute;
}
.races-root-class-name {
  flex: 1;
  left: 0px;
  bottom: 73px;
  height: auto;
  position: static;
  align-self: center;
}
.races-root-class-name1 {
  flex: 1;
  right: 0px;
  bottom: 73px;
  height: auto;
  position: static;
  align-self: center;
}
@media(max-width: 991px) {
  .races-container3 {
    width: 100%;
  }
  .races-heading {
    color: var(--dl-color-primary-100);
    width: 100%;
    align-self: center;
    text-align: center;
  }
  .races-text {
    text-align: center;
  }
  .races-weight-container {
    width: 100%;
  }
  .races-age-container {
    width: 100%;
  }
  .races-since-container {
    width: 100%;
  }
  .races-since-container1 {
    width: 100%;
  }
  .races-root-class-name {
    align-self: center;
  }
  .races-root-class-name1 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .races-root-class-name {
    width: 100%;
    align-self: center;
  }
  .races-root-class-name1 {
    width: 100%;
    position: static;
    align-self: center;
  }
}
@media(max-width: 479px) {
  .races-ostatnie-starty {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .races-container3 {
    width: 100%;
    height: auto;
  }
  .races-container4 {
    width: 100%;
    margin-left: 0px;
  }
  .races-weight-container {
    width: 100%;
  }
  .races-age-container {
    width: 100%;
  }
  .races-since-container {
    width: 100%;
  }
  .races-since-container1 {
    width: 100%;
  }
  .races-root-class-name {
    height: auto;
    align-self: center;
  }
  .races-root-class-name1 {
    height: auto;
    align-self: center;
  }
}
