.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  height: 80px;
  display: flex;
  position: relative;
  max-width: 1400px;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  justify-content: space-between;
}
.home-link {
  display: contents;
}
.home-image {
  top: 0px;
  flex: 0;
  left: 0px;
  right: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  text-decoration: none;
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon {
  fill: var(--dl-color-primary-100);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
@media(max-width: 767px) {
  .home-header {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
  }
}
