:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-800: #cbcbcbff;
  --dl-color-gray-900: #D9D9D9;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #d9fd27ff;
  --dl-color-gray-white: #FFFFFF;
  --dl-space-space-unit: 8px;
  --dl-color-primary-100: #d9fd27ff;
  --dl-color-primary-300: #000000ff;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-radius-radius-round: 50%;
  --dl-size-size-maxcontent: 1414px;
  --dl-space-space-halfunit: 4px;
  --dl-space-space-tenunits: 80px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 40px;
  --dl-space-space-fourunits: 32px;
  --dl-space-space-doubleunit: 16px;
  --dl-space-space-tripleunit: 24px;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: Arial;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list-item {
  display: list-item;
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  font-family: Arial;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  font-family: Arial;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list-title {
  color: var(--dl-color-primary-100);
  font-size: 1em;
}
.list-value {
  color: var(--dl-color-primary-100);
  right: 0px;
  bottom: 0px;
  position: absolute;
  font-size: 12px;
  margin-top: var(--dl-space-space-halfunit);
}
.Section-Text {
  font-size: 18px;
  font-family: Playfair Display;
  font-weight: 600;
  line-height: 1.55;
  letter-spacing: 2;
  text-transform: none;
  text-decoration: none;
}
.Card-Text {
  font-size: 16px;
  font-style: normal;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 1.55;
  letter-spacing: 1;
  text-transform: none;
  text-decoration: none;
}
.Content {
  font-size: 16px;
  font-family: Arial;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Card-Heading {
  font-size: 24px;
  font-style: normal;
  font-family: Overpass;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Anchor {
  font-size: 16px;
  font-family: Overpass;
  font-weight: 700;
  line-height: 1.55;
  text-transform: capitalize;
  text-decoration: none;
}
.Navbar-Link {
  font-size: 24px;
  font-family: Overpass;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Section-Heading {
  font-size: 48px;
  font-family: Overpass;
  font-weight: 900;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}


@media(max-width: 479px) {
  .list-title {
    font-size: 1em;
  }
}
